enum Colors {
	borderBlack= '#111111',
	pageBackgroundBlack = '#222222',
	offerBackgroundBlack = '#2D2D2C',
	limeGreen = '#9400D4',
	darkLimeGreen = '#4D2989',
	lightLimeGreen = '#9400D4',
	switchLimeGreen = '#9400D4',
	backgroundDarkLimeGreen = '#2A3003',
	white = '#FFFFFF',
	whiteLightlyDimmed = 'rgba(255, 255, 255, 0.9)',
	whiteDimmed = 'rgba(255, 255, 255, 0.8)',
	whiteHalfOpacity = 'rgba(255, 255, 255, 0.5)',
	headerButtonBlack = '#1B1B1B',
	textBlack = '#1A1A1A',
	textGray = '#494949',
	textLightGray = '#A1A1A1',
	switchGray = '#E7E7E7',
	backgroundBlack = '#1F1F1F',
	dividerGray = '#979797',
	dividerLight = '#E9E9E9',
	errorRed = '#DE1C22',
	paypalBorderGray = '#797979',
	offerFooterBorderGray = '#515151',
	feedbackLightDivider = '#F8F8F8',
	paymentContactGray = '#454545',
	calendarButtonGray = '#616161',
	discountRed = '#D54A4A',
}

export default Colors;
